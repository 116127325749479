<template>
  <section class="old-trees">
    <h1>Old Trees</h1>

    <PhotoSwipe folder="oldtrees" :photo-number="48" />

  </section>
</template>

<script>
export default {
  name: 'OldTrees'
}
</script>

<style lang="scss">
.old-trees {
}
</style>
